<template>
  <v-sheet
    :class="
      flipped
        ? 'flip-container flipped transparent'
        : 'flip-container transparent'
    "
  >
    <v-sheet rounded class="transparent flipper pb-2">
      <div class="front">
        <slot name="front"></slot>
        <div
          class="progressActive--text link pt-2"
          
          @click="toggle()"
        >
          <v-icon
             class="progressActive--text link ml-2 "
            x-large
          >
            mdi-flip-to-back
          </v-icon>
          {{ labelFront }}
        </div>
      </div>
      <div class="back mb-2">
        <slot v-if="flipped" name="back"></slot>
        <span class="silver--text link ml-2" @click="toggle()">
          <v-icon class="silver--text mt-n2" x-large>
            mdi-flip-to-front
          </v-icon>
          {{ labelBack }}
        </span>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'FlipCard',
  data: function() {
    return {
      flipped: false
    }
  },
  props: {
    labelFront: {
      type: String,
      default: 'FLIP'
    },
    labelBack: {
      type: String,
      default: 'FLIP'
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.flipped = !this.flipped
      if (this.fliped) this.$emit('back')
      else this.$emit('front')
    }
  }
}
</script>

<style type="text/css" scoped>
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>
